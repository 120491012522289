<template>
    <NotFound />
</template>

<script>
import NotFound from '@/components/NotFound.vue';

export default {
  name: 'App',
  components: {
    NotFound,
  },
};
</script>

<style>
</style>
